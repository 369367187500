<template>
  <v-container fluid class="px-3 mt-n6">
    <v-row class="pa-0">
      <v-col sm="12" class="text-caption font-weight-bold">
        Descontos
      </v-col>
      <v-col
        sm="12"
        v-for="entry in discounts"
        :key="entry.id"
        class="pa-0 ma-0"
      >
        <v-card
          class="pa-3 my-2 elevation-1"
          v-if="
            entry.status == discountApprovalStatus.APPROVED ||
              (userType == 'internal' &&
                entry.status != discountApprovalStatus.APPROVED)
          "
        >
          <!-- Descrição desconto -->
          <v-row no-gutters>
            <v-col cols="9">
              <div class="flex-item text-caption font-weight-bold">
                {{ entry.message }}
              </div>
              <div class="flex-item text-body-1">
                BRL {{ withoutSymbolCurrency(entry.value) }}
              </div>
              <div class="flex-item text-caption text--secondary">
                {{
                  discountIsOnlyForTodayAndInvoiceArentPayed(entry)
                    ? "Desconto aplicado para o dia atual"
                    : ""
                }}
              </div>
            </v-col>

            <v-col class="flex-item" cols="3" v-if="userType == 'internal'">
              <default-remove-button
                classname="d-block ml-auto"
                @click="onRemove(entry)"
                :disabled="isRemovingOrCannotRemove(entry)"
                :loading="removing"
                v-if="canDeleteDiscount === true"
              />
            </v-col>

            <v-col cols="9" v-if="entry.removing" class="mt-2">
              <v-text-field
                label="Motivo"
                v-model="motive"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="3"
              v-if="entry.removing"
              class="d-flex ml-auto my-auto"
            >
              <v-btn
                icon
                @click="removeDiscount(entry)"
                class="d-block ml-auto"
                :loading="removing"
                :disabled="!motive"
              >
                <v-icon>
                  mdi-check
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-card-text
            v-if="
              entry.approval === true &&
                (user.user_role_id == null ||
                  user.user_role_id == userTypes.ADMINISTRATOR)
            "
          >
            <v-timeline align-left dense>
              <v-timeline-item
                color="green"
                small
                v-show="entry.status == discountApprovalStatus.APPROVED"
              >
                <div>
                  <div class="font-weight-normal">
                    <strong>Aprovada</strong> -
                    {{ entry.updated_at_br_pattern }}
                  </div>
                  <div>O desconto foi <b>aprovado</b>.</div>
                </div>
              </v-timeline-item>
              <v-timeline-item
                color="grey"
                small
                v-show="entry.status == discountApprovalStatus.PENDING"
              >
                <div>
                  <div class="font-weight-normal">
                    <strong>Pendente</strong> -
                    {{ entry.created_at_br_pattern }}
                  </div>
                  <div>
                    O desconto foi solicitado e está
                    <b>aguardando</b> aprovação.
                  </div>
                </div>
              </v-timeline-item>
              <v-timeline-item
                color="red"
                small
                v-show="entry.status == discountApprovalStatus.DISAPPROVED"
              >
                <div>
                  <div class="font-weight-normal">
                    <strong>Reprovado</strong> -
                    {{ entry.updated_at_br_pattern }}
                  </div>
                  <div>O desconto foi <b>reprovado</b>.</div>
                </div>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col
        v-show="
          user.user_role_id == null ||
            user.user_role_id == userTypes.ADMINISTRATOR
        "
        sm="12"
        v-for="entry in discountApprovals"
        :key="entry.id"
        class="pa-0 ma-0"
      >
        <v-card class="pa-3 my-2 elevation-1">
          <v-row no-gutters>
            <v-col cols="9">
              <div class="flex-item text-caption font-weight-bold">
                {{ entry.type.name }}
              </div>
              <div class="flex-item text-body-1">
                BRL {{ withoutSymbolCurrency(entry.value) }}
              </div>
              <div class="flex-item text-caption text--secondary">
                {{
                  discountIsOnlyForTodayAndInvoiceArentPayed(entry)
                    ? "Desconto aplicado para o dia atual"
                    : ""
                }}
              </div>
            </v-col>

            <v-col class="flex-item" cols="3">
              <default-remove-button
                classname="d-block ml-auto"
                @click="onRemove(entry)"
                :disabled="isRemovingOrCannotRemove()"
                :loading="removing"
              />
            </v-col>

            <v-col cols="9" v-if="entry.removing" class="mt-2">
              <v-text-field
                label="Motivo"
                v-model="motive"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="3"
              v-if="entry.removing"
              class="d-flex ml-auto my-auto"
            >
              <v-btn
                icon
                @click="removeDiscount(entry)"
                class="d-block ml-auto"
                :loading="removing"
                :disabled="!motive"
              >
                <v-icon>
                  mdi-check
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-card-text>
            <v-timeline align-left dense>
              <v-timeline-item
                color="grey"
                small
                v-show="entry.status == discountApprovalStatus.PENDING"
              >
                <div>
                  <div class="font-weight-normal">
                    <strong>Pendente</strong> -
                    {{ entry.created_at_br_pattern }}
                  </div>
                  <div>
                    O desconto foi solicitado e está
                    <b>aguardando</b> aprovação.
                  </div>
                </div>
              </v-timeline-item>
              <v-timeline-item
                color="red"
                small
                v-show="entry.status == discountApprovalStatus.DISAPPROVED"
              >
                <div>
                  <div class="font-weight-normal">
                    <strong>Reprovado</strong> -
                    {{ entry.updated_at_br_pattern }}
                  </div>
                  <div>O desconto foi <b>reprovado</b>.</div>
                </div>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import DefaultRemoveButton from "@/components/layout/DefaultRemoveButton.vue";
import { bus } from "@/main";

import InvoiceService from "@/app/Services/InvoiceService";
import FormMixin from "@/components/misc/mixins/FormMixin";
import InvoiceVerify from "../../domain/InvoiceVerify";
import DiscountApprovalStatus from "@/pages/DiscountApprovals/enums/DiscountApprovalStatus";
import UserTypes from "@/enums/UserTypes";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";

export default {
  components: { DefaultRemoveButton },
  name: "InvoiceDiscountInfo",
  props: {
    invoice: Object
  },
  mixins: [FormMixin, AuthorizationMixin],
  async created() {
    this.canDeleteDiscount = await this.canAccess({
      route: "/discount/approvals",
      type: "write"
    });
  },
  data() {
    return {
      removing: false,
      motive: "Remoção de Desconto",
      discountApprovalStatus: DiscountApprovalStatus,
      userTypes: UserTypes,
      canDeleteDiscount: false
    };
  },
  methods: {
    discountIsOnlyForTodayAndInvoiceArentPayed(entry) {
      if (!entry.only_for_one_day) {
        return false;
      }

      if ([4, 5, 6, 8, 9].includes(this.invoice.status_id)) {
        return false;
      }

      return true;
    },
    isRemovingOrCannotRemove(entry) {
      if (this.removing) {
        return true;
      }

      const invoiceVerify = new InvoiceVerify();

      if (
        invoiceVerify.alreadyPayed(this.invoice) ||
        invoiceVerify.billOrPixGenerated(this.invoice) ||
        invoiceVerify.hasPaymentWriteOff(this.invoice) ||
        entry.status != DiscountApprovalStatus.APPROVED
      ) {
        return true;
      }

      return false;
    },
    async onRemove(entry) {
      entry.removing = true;

      this.$forceUpdate();
    },
    async removeDiscount(entry) {
      try {
        this.$emit("loading", true);
        this.removing = true;

        const query = {
          params: {
            motive: this.motive
          }
        };

        await InvoiceService.build()
          .discount()
          .destroy(entry.id, { query });

        this.invoice.entries = this.invoice.entries.filter(
          item => item.id != entry.id
        );

        this.showToast({ message: "Desconto removido com sucesso" });

        entry.removing = false;
        bus.$emit("include_discount_remove");
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.$emit("loading", false);
        this.removing = false;
      }
    },
    showDeleteDiscountDialog() {
      return new Promise((resolve, reject) => {
        bus.$emit("showNotificationDialog", {
          status: "error",
          title: `Deseja realmente remover esse desconto?`,
          continue: () => resolve(true),
          dispose: () => reject(false)
        });
      });
    }
  },
  computed: {
    discountApprovals() {
      return this.invoice.discount_approvals.filter(
        approval => approval.status != DiscountApprovalStatus.APPROVED
      );
    },
    discounts() {
      const discounts = [];

      this.invoice.entries.forEach(entry => {
        discounts.push({
          id: entry.id,
          message: entry.history.message,
          value: entry.value,
          removing: entry.removing,
          only_for_one_day: entry.only_for_one_day,
          approval: entry.discount_approval ? true : false,
          date: entry.created_at,
          status: entry.discount_approval
            ? entry.discount_approval.status
            : null,
          updated_at_br_pattern: entry.discount_approval
            ? entry.discount_approval.updated_at_br_pattern
            : null,
          created_at_br_pattern: entry.discount_approval
            ? entry.discount_approval.created_at_br_pattern
            : null
        });
      });

      this.invoice.discount_approvals
        .filter(approval => approval.status != DiscountApprovalStatus.APPROVED)
        .forEach(entry => {
          discounts.push({
            id: entry.invoice_entry_id,
            message: entry.type.message,
            value: entry.value,
            removing: false,
            only_for_one_day: entry.only_for_one_day,
            approval: true,
            date: entry.created_at,
            status: entry.status,
            updated_at_br_pattern: entry.updated_at_br_pattern,
            created_at_br_pattern: entry.created_at_br_pattern
          });
        });

      return discounts.sort((a, b) => new Date(b.date) - new Date(a.date));
    },
    user() {
      const storage = localStorage.getItem("user");

      if (storage !== null) {
        const parsedStorage = JSON.parse(storage);

        return parsedStorage.user;
      }

      return null;
    },
    userType() {
      const hasPayerDefined = !!(this.user.payer_id || this.user.group_id);
      const hasCompanyDefined =
        this.user.companies && this.user.companies.length >= 1;

      if (hasPayerDefined) {
        return "external";
      }

      if (hasCompanyDefined) {
        return "internal";
      }

      return "internal";
    }
  }
};
</script>

<style></style>
