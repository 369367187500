var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-3 mt-n6",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"text-caption font-weight-bold",attrs:{"sm":"12"}},[_vm._v(" Descontos ")]),_vm._l((_vm.discounts),function(entry){return _c('v-col',{key:entry.id,staticClass:"pa-0 ma-0",attrs:{"sm":"12"}},[(
          entry.status == _vm.discountApprovalStatus.APPROVED ||
            (_vm.userType == 'internal' &&
              entry.status != _vm.discountApprovalStatus.APPROVED)
        )?_c('v-card',{staticClass:"pa-3 my-2 elevation-1"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"flex-item text-caption font-weight-bold"},[_vm._v(" "+_vm._s(entry.message)+" ")]),_c('div',{staticClass:"flex-item text-body-1"},[_vm._v(" BRL "+_vm._s(_vm.withoutSymbolCurrency(entry.value))+" ")]),_c('div',{staticClass:"flex-item text-caption text--secondary"},[_vm._v(" "+_vm._s(_vm.discountIsOnlyForTodayAndInvoiceArentPayed(entry) ? "Desconto aplicado para o dia atual" : "")+" ")])]),(_vm.userType == 'internal')?_c('v-col',{staticClass:"flex-item",attrs:{"cols":"3"}},[(_vm.canDeleteDiscount === true)?_c('default-remove-button',{attrs:{"classname":"d-block ml-auto","disabled":_vm.isRemovingOrCannotRemove(entry),"loading":_vm.removing},on:{"click":function($event){return _vm.onRemove(entry)}}}):_vm._e()],1):_vm._e(),(entry.removing)?_c('v-col',{staticClass:"mt-2",attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"label":"Motivo","rules":[_vm.rules.required]},model:{value:(_vm.motive),callback:function ($$v) {_vm.motive=$$v},expression:"motive"}})],1):_vm._e(),(entry.removing)?_c('v-col',{staticClass:"d-flex ml-auto my-auto",attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"d-block ml-auto",attrs:{"icon":"","loading":_vm.removing,"disabled":!_vm.motive},on:{"click":function($event){return _vm.removeDiscount(entry)}}},[_c('v-icon',[_vm._v(" mdi-check ")])],1)],1):_vm._e()],1),(
            entry.approval === true &&
              (_vm.user.user_role_id == null ||
                _vm.user.user_role_id == _vm.userTypes.ADMINISTRATOR)
          )?_c('v-card-text',[_c('v-timeline',{attrs:{"align-left":"","dense":""}},[_c('v-timeline-item',{directives:[{name:"show",rawName:"v-show",value:(entry.status == _vm.discountApprovalStatus.APPROVED),expression:"entry.status == discountApprovalStatus.APPROVED"}],attrs:{"color":"green","small":""}},[_c('div',[_c('div',{staticClass:"font-weight-normal"},[_c('strong',[_vm._v("Aprovada")]),_vm._v(" - "+_vm._s(entry.updated_at_br_pattern)+" ")]),_c('div',[_vm._v("O desconto foi "),_c('b',[_vm._v("aprovado")]),_vm._v(".")])])]),_c('v-timeline-item',{directives:[{name:"show",rawName:"v-show",value:(entry.status == _vm.discountApprovalStatus.PENDING),expression:"entry.status == discountApprovalStatus.PENDING"}],attrs:{"color":"grey","small":""}},[_c('div',[_c('div',{staticClass:"font-weight-normal"},[_c('strong',[_vm._v("Pendente")]),_vm._v(" - "+_vm._s(entry.created_at_br_pattern)+" ")]),_c('div',[_vm._v(" O desconto foi solicitado e está "),_c('b',[_vm._v("aguardando")]),_vm._v(" aprovação. ")])])]),_c('v-timeline-item',{directives:[{name:"show",rawName:"v-show",value:(entry.status == _vm.discountApprovalStatus.DISAPPROVED),expression:"entry.status == discountApprovalStatus.DISAPPROVED"}],attrs:{"color":"red","small":""}},[_c('div',[_c('div',{staticClass:"font-weight-normal"},[_c('strong',[_vm._v("Reprovado")]),_vm._v(" - "+_vm._s(entry.updated_at_br_pattern)+" ")]),_c('div',[_vm._v("O desconto foi "),_c('b',[_vm._v("reprovado")]),_vm._v(".")])])])],1)],1):_vm._e()],1):_vm._e()],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }