export default class InvoiceVerify {
  constructor() {
    this.data = {
      paymentConfirmedReferences: ["payment_confirmed"],
      billetOrPixGeneratedReferences: ["generated_billet", "generated_pix"]
    };
  }

  /**
   * verifica se a fatura ja foi paga
   * @param {Object} invoice
   * @returns {boolean}
   */
  alreadyPayed(invoice) {
    try {
      const alreadyPayed = this.data.paymentConfirmedReferences.some(
        refs => refs == invoice.status.refs
      );

      return alreadyPayed;
    } catch (error) {
      return false;
    }
  }

  getTotalWithDiscounts(invoice = {}) {
    let result = invoice.result_total;

    result += Number(invoice.result_entries);

    return result;
  }

  getTotalToPreview(invoice = {}) {
    let result = invoice.total;

    result += Number(invoice.result_entries);
    result += Number(invoice.result_charges);

    return result;
  }

  getDiscount(entry) {
    const discountTypeId = 1;
    const isDiscount = entry.invoice_entry_type_id == discountTypeId;

    if (isDiscount) {
      return Number(entry.value);
    }

    return 0;
  }

  hasEntries(invoice) {
    return invoice.entries && invoice.entries.length;
  }

  hasPendingEntries(invoice) {
    return invoice.discount_approvals && invoice.discount_approvals.length;
  }

  billOrPixGenerated(invoice) {
    try {
      const alreadyPayed = this.data.billetOrPixGeneratedReferences.some(
        refs => refs == invoice.status.refs
      );

      return alreadyPayed;
    } catch (error) {
      return false;
    }
  }

  hasInvoiceEntry(invoice) {
    try {
      return invoice.status.refs == "invoice_entry";
    } catch (error) {
      return false;
    }
  }

  hasCanceledBill(invoice) {
    try {
      return invoice.status.refs == "payment_cancelled";
    } catch (error) {
      return false;
    }
  }

  isUnificationInvoice(invoice) {
    try {
      return this.isUnified(invoice) && invoice.unifications.length >= 1;
    } catch (error) {
      return false;
    }
  }

  wasUnificated(invoice) {
    try {
      return this.isUnified(invoice) && invoice.unifications.length <= 0;
    } catch (error) {
      return false;
    }
  }

  isCreditNote(invoice) {
    return invoice.type == "credit_note";
  }

  isUnified(invoice) {
    return invoice.type == "unified";
  }

  isDemurrage(invoice) {
    return (
      invoice.type == "demurrage" ||
      (invoice.charge_period && invoice.charge_period.length > 0)
    );
  }

  isFreight(invoice) {
    return invoice.type == "invoice" || (invoice.tax && invoice.tax.length > 0);
  }

  hasPaymentWriteOff(invoice) {
    try {
      return invoice.status.refs == "payment_write_off";
    } catch (error) {
      return false;
    }
  }
}
