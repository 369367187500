<template>
  <v-dialog v-model="dialog" :width="options.width">
    <Dialog :options="options" @dispose="dispose" />
  </v-dialog>
</template>

<script>
import { bus } from "@/main";
import Dialog from "./components/NotificationDialog.vue";

export default {
  components: {
    Dialog
  },
  name: "DialogHandler",
  data() {
    return {
      dialog: false,
      options: {
        width: "580"
      }
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        return;
      }

      if (this.options.dispose) {
        this.options.dispose();
      }
    }
  },
  mounted() {
    setTimeout(() =>
      bus.$on("showNotificationDialog", this.showNotificationDialog)
    );
  },
  beforeDestroy() {
    bus.$off("showNotificationDialog");
  },
  methods: {
    showNotificationDialog(options) {
      this.options = { ...this.options, ...options };

      this.dialog = true;
    },
    dispose() {
      this.dialog = false;
    }
  }
};
</script>

<style></style>
