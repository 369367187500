<template>
  <v-dialog v-model="dialog" width="60%" @change="onToggle">
    <v-card :loading="loading">
      <v-container fluid>
        <v-row>
          <v-col sm="12">
            <span class="text-h5">
              Incluir Desconto - Fatura {{ invoice.invoice }}
            </span>
          </v-col>

          <v-col sm="12">
            <span class="text-subtitle-1 text-secondary">
              BRL {{ getTotalWithDiscounts() }}
            </span>
          </v-col>
        </v-row>

        <v-row v-if="loading">
          <centralized-message>
            <template #message>
              Carregando...
            </template>
          </centralized-message>
        </v-row>

        <v-row v-else-if="error">
          <centralized-message>
            <template #message>
              {{ error.message }}
            </template>

            <template #complemention>
              {{ error.complemention }}
            </template>
          </centralized-message>
        </v-row>

        <v-row v-else>
          <v-col cols="12">
            <v-form ref="form" v-model="valid" class="flex-item d-flex w-100">
              <v-container fluid>
                <v-row>
                  <v-col cols="10">
                    <v-currency-field
                      label="Desconto"
                      v-model="discount.value"
                      :rules="[
                        rules.required,
                        discountGreaterThanInvoiceValue,
                        discountGreaterThanZero
                      ]"
                    />
                  </v-col>

                  <v-col cols="2">
                    <span class="text-caption text--secondary">
                      Aplicar apenas para hoje
                    </span>

                    <v-checkbox
                      class="mt-n1"
                      v-model="discount.only_for_one_day"
                    />
                  </v-col>

                  <v-col cols="12">
                    <discount-reason-select
                      v-model="discount.reason"
                      :rules="[rules.required]"
                    />
                  </v-col>

                  <v-col class="d-flex" cols="12" sm="12">
                    <v-select
                      :items="types"
                      v-model="discount.type"
                      item-text="name"
                      item-value="id"
                      label="Tipo de Desconto"
                      :rules="[rules.required]"
                      :loading="loadingTypes"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-col>

          <v-divider />

          <v-col sm="12" class="mt-6 d-flex flex-row">
            <v-spacer />

            <v-btn class="font-weight-bold" @click="cancel" text color="text">
              Cancelar
            </v-btn>

            <v-btn
              class="font-weight-bold"
              color="primary"
              text
              @click="applyDiscount"
              :loading="applyingDiscount"
              :disabled="!valid || this.error"
            >
              Aplicar Desconto
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { bus } from "@/main";

import CentralizedMessage from "../misc/CentralizedMessage.vue";
import FormMixin from "../misc/mixins/FormMixin";

import DiscountReasonSelect from "./components/DiscountReasonSelect.vue";

import InvoiceService from "@/app/Services/InvoiceService";
import InvoicesDiscountsTypesService from "@/app/Services/InvoicesDiscountsTypesService";

import InvoiceVerify from "@/pages/Invoice/domain/InvoiceVerify";

const invoiceVerify = new InvoiceVerify();

export default {
  components: {
    CentralizedMessage,
    DiscountReasonSelect
  },
  name: "IncludeDiscountDialog",
  mixins: [FormMixin],
  data() {
    return {
      dialog: false,
      loading: false,
      loadingTypes: false,
      applyingDiscount: false,
      valid: false,
      invoice: {},
      discount: {
        only_for_one_day: true
      },
      types: []
    };
  },
  created() {
    bus.$on("include_discount_remove", () => this.$emit("discount"));
    bus.$on("include_discount_show", this.includeDiscount);

    this.getApprovalGroupType();
  },
  methods: {
    getTotalWithDiscounts() {
      const result = invoiceVerify.getTotalWithDiscounts(this.invoice);

      return this.withoutSymbolCurrency(result);
    },
    async includeDiscount(invoice) {
      try {
        this.dialog = true;
        this.invoice = invoice;

        this.discount = {
          only_for_one_day: true
        };
        this.error = null;

        const result = this.validate(invoice);

        if (result) {
          this.invoice = invoice;
          this.invoice.totalWithDiscounts = invoiceVerify.getTotalWithDiscounts(
            this.invoice
          );
        }
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      }
    },
    async applyDiscount() {
      try {
        this.applyingDiscount = true;

        await InvoiceService.build()
          .discount()
          .store({
            invoice_id: this.invoice.id,
            value: this.discount.value,
            motive: this.discount.reason.name,
            entry_type_id: 1,
            only_for_one_day: this.discount.only_for_one_day,
            type: this.discount.type
          });

        this.showToast({ message: "O desconto foi enviado para aprovação" });

        this.dialog = false;
        this.invoice = {};
        this.discount = {};
        this.$emit("discount");
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.applyingDiscount = false;
      }
    },
    discountGreaterThanInvoiceValue() {
      if (this.invoice.totalWithDiscounts <= Number(this.discount.value)) {
        return "O valor deve ser menor que o valor da tarifa";
      }

      return null;
    },
    discountGreaterThanZero() {
      if (!this.discount.value || Number(this.discount.value) <= 0) {
        return "Valor deve ser maior que zero (0,00)";
      }

      return null;
    },
    validate(invoice) {
      const alreadyGenerated =
        invoiceVerify.billOrPixGenerated(invoice) ||
        invoiceVerify.alreadyPayed(invoice);

      if (alreadyGenerated) {
        this.error = {
          message: "Fatura já gerada",
          complemention: "Não é possível aplicar descontos a faturas já geradas"
        };

        return false;
      }

      const isUnificationChildren = invoiceVerify.wasUnificated(invoice);

      if (isUnificationChildren) {
        this.error = {
          message: "Desconto não aplicado",
          complemention:
            "O Desconto não pode ser aplicado em faturas unificadas."
        };

        return false;
      }

      return true;
    },
    cancel() {
      this.dialog = false;
      this.invoice = {};
      this.discount = {
        only_for_one_day: true
      };
    },
    async getApprovalGroupType() {
      try {
        this.loadingTypes = true;
        const query = {
          params: {
            per_page: -1
          }
        };

        const response = await InvoicesDiscountsTypesService.build().index({
          query
        });

        if (response.data.length > 0) {
          this.types = response.data.filter(types => types.types.length > 0);
        }
        this.loadingTypes = false;
      } catch (error) {
        this.error = this.getExceptionResponseMessage(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
