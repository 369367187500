<template>
  <v-form ref="form" v-model="valid">
    <v-container fluid>
      <v-row>
        <v-col md="6" xs="12">
          <v-text-field
            label="Descrição"
            v-model="value.name"
            placeholder="Digite..."
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col md="8" xs="12">
          <v-range-slider
            v-model="value.range"
            min="0"
            max="50000"
            class="align-center"
            :rules="[rules.required]"
            thumb-label="always"
          >
            <template v-slot:prepend>
              <v-text-field
                :value="value.range[0]"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                @change="$set(value.range, 0, $event)"
              ></v-text-field>
            </template>
            <template v-slot:append>
              <v-text-field
                :value="value.range[1]"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                @change="$set(value.range, 1, $event)"
              ></v-text-field>
            </template>
          </v-range-slider>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormMixin from "@/components/misc/mixins/FormMixin";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";
import DiscountApprovalGroupsService from "@/app/Services/DiscountApprovalGroupsService";

import { bus } from "@/main";

export default {
  name: "DiscountApprovalGroupsFormGeneral",
  mixins: [FormMixin, AuthorizationMixin],
  props: {
    value: Object,
    loading: Boolean
  },
  watch: {
    valid() {
      this.$emit("valid", this.valid);
    },
    value(updatedPayerGroup) {
      this.payerGroup = updatedPayerGroup;
    }
  },
  async created() {
    // this.canEditContract = await this.canAccess({
    //   route: "/user/contract",
    //   type: "write"
    // });
  },
  data() {
    return {
      responsivity: {
        maxWidth: "256px",
        maxHeight: "256px"
      },
      registering: false,
      payer: "",
      valid: false,
      canEditContract: false,
      headers: [
        {
          text: "Id",
          align: "start",
          value: "id",
          width: "40"
        },
        {
          text: "Nome",
          align: "start",
          value: "name"
        },
        {
          text: "CNPJ/CPF",
          align: "start",
          value: "cnpj_cpf"
        },
        {
          text: "Status",
          value: "payer_status",
          align: "center",
          width: "30"
        },
        { text: "#", value: "updated_at", align: "center", width: "30" }
      ]
    };
  },
  methods: {
    getFormRef() {
      return this.$refs.form;
    },
    remove(item) {
      bus.$emit("showNotificationDialog", {
        status: "error",
        title: "Deseja remover esse pagador?",
        continue: () => {
          const idx = this.value.payerGroup.findIndex(con => con.id == item.id);

          this.value.payerGroup.splice(idx, 1);

          this.save();
        }
      });
    },
    async save() {
      try {
        const payers = Object.assign([], this.value.payerGroup);

        const formPayers = {
          id: this.value.id,
          payers: payers
        };

        const service = DiscountApprovalGroupsService.build();

        this.payer = "";

        this.registering = true;

        const data = await service.update(formPayers);

        this.value.payerGroup = data.payerGroup;
      } catch (e) {
        console.log(e);
        this.registering = false;
        this.defaultCatchError(e);
      } finally {
        this.registering = false;
      }
    },
    async addPayer() {
      try {
        const payers = Object.assign([], this.value.payerGroup);

        payers.unshift({
          created: true,
          cnpj_cpf: this.payer.cnpj_cpf
        });

        const formPayers = {
          id: this.value.id,
          payers: payers
        };

        const service = DiscountApprovalGroupsService.build();

        this.payer = "";

        this.registering = true;

        const data = await service.update(formPayers);

        this.value.payerGroup = data.payerGroup;
      } catch (e) {
        console.log(e);
        this.registering = false;
        this.defaultCatchError(e);
      } finally {
        this.registering = false;
      }
    }
  }
};
</script>

<style></style>
