<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="pagination.itemsPerPage"
    hide-default-footer
    loading-text="Carregando dados, aguarde..."
    class="elevation-0"
  >
    <template v-slot:item.action="{ item }">
      <div class="d-flex flex-row">
        <default-edit-button @click="edit(item)" />

        <default-remove-button @click="remove(item)" />
      </div>
    </template>
  </v-data-table>
</template>

<script>
import DefaultEditButton from "@/components/layout/DefaultEditButton.vue";
import DefaultRemoveButton from "@/components/layout/DefaultRemoveButton.vue";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";

export default {
  components: {
    DefaultEditButton,
    DefaultRemoveButton
  },
  name: "DiscountTypesTable",
  props: {
    items: Array,
    pagination: Object
  },
  data() {
    return {
      headers: [
        {
          text: "Nome do Tipo",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Ações",
          sortable: false,
          value: "action",
          width: "80"
        }
      ],
      authorization: {
        route: "/discount-types"
      },
      params: []
    };
  },
  created() {},
  mixins: [AuthorizationMixin],
  methods: {
    edit(item) {
      this.$emit("edit", item);
    },
    remove(item) {
      this.$emit("remove", item);
    }
  }
};
</script>

<style></style>
