<template>
  <v-container fluid class="pb-12">
    <breadcrumb route-name="Tipos de Usuário" />

    <v-card :loading="loading">
      <v-container fluid>
        <!-- TABS -->
        <v-row>
          <v-col cols="9">
            <v-tabs color="secondary" v-model="tab">
              <v-tab href="#info" class="font-weight-bold">
                Informações
              </v-tab>

              <v-tab
                v-for="accessType in accessTypes"
                :key="accessType.type"
                :href="`#${accessType.type}`"
                class="font-weight-bold"
              >
                {{ accessType.text }}
              </v-tab>
            </v-tabs>
          </v-col>

          <v-col cols="3">
            <authorization-gate
              auth-route="/user-role-permission"
              auth-type="write"
              class="d-flex flex-item ml-auto"
            >
              <v-btn
                class="white--text ml-auto mr-6"
                color="primary"
                :loading="saving"
                :disabled="disabled"
                @click="save"
                v-if="$route.params.id != 1"
              >
                Salvar
              </v-btn>
            </authorization-gate>
          </v-col>
        </v-row>

        <!-- CONTEUDO -->
        <v-row>
          <v-col>
            <v-tabs-items v-model="tab">
              <v-tab-item value="info">
                <access-info-form v-model="userRole" />
              </v-tab-item>

              <v-tab-item
                v-for="accessType in accessTypes"
                :key="accessType.type"
                :value="accessType.type"
              >
                <access-list
                  :loading="loading"
                  :access-type="accessType"
                  :permissions="permissions"
                  :user-type-access-permissions="userTypeAccessPermissions"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import AccessList from "./components/AccessList.vue";
import UserTypeAccessState from "./domain/UserTypeAccessState";
import UserRoleService from "@/app/Services/UserRoleService";
import UserRolePermissionService from "@/app/Services/UserRolePermissionService";
import AuthorizationGate from "@/components/Authorization/AuthorizationGate.vue";
import AccessInfoForm from "./components/AccessInfoForm.vue";

export default {
  components: { Breadcrumb, AccessList, AuthorizationGate, AccessInfoForm },
  name: "UserTypeAccessSave",
  data() {
    return {
      tab: null,
      loading: false,
      userRole: {},
      permissions: [],
      saving: false,
      userTypeAccessPermissions: [],
      accessTypes: [
        { text: "ACESSOS", type: "non_grouped" },
        { text: "USUÁRIOS", type: "users" },
        { text: "CADASTROS", type: "register" },
        { text: "RELATÓRIOS", type: "reports" },
        { text: "DESCONTOS", type: "discount" }
      ]
    };
  },
  computed: {
    disabled() {
      return !(UserTypeAccessState().getters.permissions.length >= 1);
    }
  },
  created() {
    this.loadAllPermissions();

    if (this.isToEdit()) {
      this.loadUserTypeAccessPermissions();
    }
  },
  methods: {
    async save() {
      try {
        this.saving = true;

        let response;

        const permissions = UserTypeAccessState().state.permissions;

        let id = this.$route.params.id;

        if (this.isToEdit()) {
          response = await UserRoleService.build().update(this.userRole);

          id = response.data.id;
        } else {
          delete this.userRole.id;

          response = await UserRoleService.build().store(this.userRole);

          id = response.data.id;
        }

        response = await UserRolePermissionService.build().update(
          permissions,
          id
        );

        this.$router.go(-1);
        this.showToast({ message: "Usuário atualizado com sucesso" });
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.saving = false;

        this.$forceUpdate();
      }
    },
    async loadAllPermissions() {
      try {
        this.loading = true;

        const response = await UserRolePermissionService.build().index();

        this.permissions = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;

        this.$forceUpdate();
      }
    },
    async loadUserTypeAccessPermissions() {
      try {
        this.loading = true;

        const response = await UserRoleService.build().show(
          this.$route.params.id
        );

        this.userRole = response.data;

        const userPermissions = response.data.permissions;

        const serializedUserPermissions = userPermissions.map(permission => {
          const operation = permission.operation;

          permission[operation] = true;

          if (operation == "write") {
            permission.read = true;
          }

          return permission;
        });

        UserTypeAccessState().dispatch(
          "loadPermissions",
          serializedUserPermissions
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }

      this.$forceUpdate();
    },
    isToEdit() {
      const isToEdit = this.$route.params.id != "new";

      return isToEdit;
    }
  }
};
</script>

<style></style>
