<template>
  <v-dialog v-model="dialog" width="60%">
    <v-card :loading="loading">
      <v-container>
        <v-row v-if="!error && !canViewInvoiceHistory">
          <extra-info-steps
            :invoice="invoice"
            @showInvoiceHistory="toggleInvoiceHistory"
          />
        </v-row>

        <v-row>
          <v-col sm="12" v-if="!error">
            <v-divider />
          </v-col>

          <v-col cols="8">
            <span class="text-h5">
              Informações da Fatura {{ invoice.invoice || "Unificada" }}
            </span>
          </v-col>
          <v-col cols="2" :offset="!invoice.file_electronic_invoice ? 2 : 0">
            <v-btn
              block
              elevation="2"
              color="error"
              v-show="invoice.url_electronic_invoice"
              :href="invoice.url_electronic_invoice"
              target="_blank"
            >
              <v-icon class="mr-2" small
                >mdi-receipt-text-arrow-left-outline</v-icon
              >
              Abrir NF-e
            </v-btn>
          </v-col>

          <v-col cols="2">
            <v-btn
              block
              elevation="2"
              color="primary"
              v-show="invoice.file_electronic_invoice"
              @click="handleDownloadFileElectronicInvoice(invoice)"
              :loading="loadingDownloadFileElectronicInvoice"
            >
              <v-icon class="mr-2" small
                >mdi-receipt-text-arrow-left-outline</v-icon
              >
              Fatura
            </v-btn>
          </v-col>

          <v-col sm="12" class="mt-n8">
            <span class="text-subtitle-2 text--secondary font-weight-bold">
              Cliente
            </span>

            <span class="text-subtitle-2 text--secondary font-weight-normal">
              {{ invoice.payer.name }}
            </span>
          </v-col>

          <v-col sm="12">
            <v-divider />
          </v-col>
        </v-row>

        <v-row v-if="error">
          <centralized-message>
            <template #message>
              {{ error.message }}
            </template>

            <template #complemention>
              {{ error.complemention }}
            </template>
          </centralized-message>
        </v-row>

        <v-row v-else-if="canViewInvoiceHistory">
          <invoice-history
            :invoice="invoice"
            @toggleInvoiceHistory="toggleInvoiceHistory"
          />
        </v-row>

        <v-row v-else>
          <v-col sm="12" v-if="invoiceVerify.wasUnificated(invoice)">
            <unified-info :invoice="invoice" @goToUnificator="goToUnificator" />
          </v-col>

          <v-col sm="12" v-if="invoiceVerify.isUnificationInvoice(invoice)">
            <unificator-info :invoice="invoice" @goToUnified="goToUnified" />

            <freight-info
              :invoice="invoice"
              v-if="invoiceVerify.isFreight(invoice)"
            />

            <demurrage-info
              :invoice="invoice"
              v-if="invoiceVerify.isDemurrage(invoice)"
            />
          </v-col>

          <v-col sm="12" v-if="invoiceVerify.isFreight(invoice)">
            <invoice-info :invoice="invoice" />

            <freight-info :invoice="invoice" />
          </v-col>

          <v-col sm="12" v-if="invoiceVerify.isDemurrage(invoice)">
            <invoice-info :invoice="invoice" />

            <demurrage-info :invoice="invoice" />
          </v-col>

          <v-col
            sm="12"
            v-if="
              invoiceVerify.hasEntries(invoice) ||
                invoiceVerify.hasPendingEntries(invoice)
            "
            class="px-0"
          >
            <invoice-discounts-info :invoice="invoice" @loading="setLoading" />
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="12" class="mt-6 d-flex flex-row">
            <v-spacer />

            <v-btn class="font-weight-bold" @click="cancel" text color="text">
              Fechar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { bus } from "@/main";
import FileSaver from "file-saver";

import InvoiceService from "@/app/Services/InvoiceService";
import CentralizedMessage from "@/components/misc/CentralizedMessage.vue";

import InvoiceVerify from "../../domain/InvoiceVerify";

import ExtraInfoSteps from "./steps/ExtraInfoSteps.vue";
import InvoiceHistory from "./steps/InvoiceHistory.vue";
import InvoiceInfo from "./InvoiceInfo.vue";

import DemurrageInfo from "./demurrage/DemurrageInfo.vue";
import FreightInfo from "./freight/FreightInfo.vue";
import UnificatorInfo from "./unify/UnificatorInfo.vue";
import UnifiedInfo from "./unify/UnifiedInfo.vue";

import InvoiceDiscountsInfo from "../invoice_discounts/InvoiceDiscountsInfo.vue";

export default {
  components: {
    CentralizedMessage,
    ExtraInfoSteps,
    DemurrageInfo,
    InvoiceInfo,
    FreightInfo,
    UnificatorInfo,
    UnifiedInfo,
    InvoiceDiscountsInfo,
    InvoiceHistory
  },
  name: "ExtraInfoDialog",
  props: {
    selected: Array
  },
  created() {
    bus.$on("extra_info_show", this.showExtraInfo);

    this.invoiceVerify = new InvoiceVerify();
  },
  computed: {},
  data() {
    return {
      invoiceVerify: null,
      dialog: false,
      loading: true,
      invoice: {
        payer: {}
      },
      canViewInvoiceHistory: false,
      loadingDownloadFileElectronicInvoice: false
    };
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.dialog = false;
        this.canViewInvoiceHistory = false;
      }
    }
  },
  methods: {
    toggleInvoiceHistory() {
      this.canViewInvoiceHistory = !this.canViewInvoiceHistory;
    },
    setLoading(loading) {
      this.loading = loading;
    },
    goToUnificator(unificator) {
      this.dialog = false;

      bus.$emit("extra_info_show", unificator);
    },
    goToUnified(unified) {
      this.dialog = false;

      bus.$emit("extra_info_show", unified);
    },
    async showExtraInfo(item) {
      try {
        this.loading = true;
        this.dialog = true;
        this.error = { message: "Carregando..." };

        const query = {
          params: {
            relationship: [
              "tax",
              "chargePeriod",
              "entries.types",
              "entries.status",
              "entries.history",
              "entries.discount_approval",
              "histories",
              "unifications",
              "unifiedFather",
              "transaction",
              "unifications.chargePeriod",
              "unifications.tax"
            ]
          }
        };

        const response = await InvoiceService.build().show(item.id, query);

        this.invoice = response.data;
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.$forceUpdate();
        this.loading = false;
        this.error = null;
      }
    },
    cancel() {
      this.dialog = false;
      this.canViewInvoiceHistory = false;
    },
    async handleDownloadFileElectronicInvoice(invoice) {
      this.loadingDownloadFileElectronicInvoice = true;
      const response = await InvoiceService.build().send(
        { invoice: invoice.invoice },
        "read-electronic-invoice/download/receipt/",
        {
          responseType: "arraybuffer"
        }
      );

      const blob = new Blob([response], { type: "application/pdf;" });

      this.loadingDownloadFileElectronicInvoice = false;

      FileSaver.saveAs(blob, invoice.file_electronic_invoice);
    }
  }
};
</script>

<style></style>
