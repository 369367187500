<template>
  <default-layout
    route-name="Descontos Concedidos"
    has-filters
    unique-filter
    :instructions="instructions"
    :loading="loading"
    @search="search"
    :uniqueFilter="false"
  >
    <template #header-actions>
      <v-spacer />

      <generate-reports-button
        table="discounts"
        label="Gerar Relatório"
        filename="relatorio_descontos"
        :filter="filter"
      />
    </template>

    <template #content>
      <discount-table :items="items" :pagination="pagination" />
    </template>

    <template #footer>
      <pagination v-model="pagination" @change="loadData" :loading="loading" />
    </template>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import FilterMixin from "@/components/Filters/FilterMixin";
import Pagination from "@/components/pagination/Pagination.vue";
import GenerateReportsButton from "@/components/Reports/GenerateReportsButton.vue";

import DiscountService from "@/app/Services/DiscountService";
import DiscountTable from "./components/DiscountTable.vue";

export default {
  components: {
    DefaultLayout,
    Pagination,
    GenerateReportsButton,
    DiscountTable
  },
  name: "Discount",
  data() {
    return {
      loading: false,
      items: [],
      instructions: {
        search_for: [
          { text: "Valor do Desconto", field: "value", type: "currency" },
          { text: "Fatura", field: "invoice" },
          {
            text: "Data de Concessão",
            field: "created_at",
            type: "date_range"
          }
        ],
        filter_for: [
          {
            text: "Faturas aptas para a criação de Nota de Crédito",
            field: "suitable_creating_credit_note",
            value: true
          }
        ]
      }
    };
  },
  mixins: [PaginationMixin, FilterMixin],
  methods: {
    async loadData() {
      try {
        this.loading = true;

        const query = this.getApiQuery();

        const response = await DiscountService.build().index({
          query
        });

        this.items = response.data;

        this.paginate(response);
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    getApiQuery() {
      return {
        params: {
          ...this.pagination,
          filter: { ...this.filter }
        }
      };
    }
  }
};
</script>

<style></style>
